import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCorrect, showIncorrect } from '../../stores/correct-and-incorrect-overlay/CorrectAndIncorrectState';
import { ImageUtility, MixpanelTracking } from '../../utility';
import Latex from 'react-latex';
import './QuizPopUp.css';
import closeImg from '../../images/actions/close.svg';




const QuizPopUp = forwardRef(({ chapter, onClose }: { chapter: NaTypes.Chapter; onClose(): void }, ref) => {

    const dispatch = useDispatch();
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const [choice, setChoice] = useState();
    const [number, setNumber] = useState(0);
    const [question, setQuestion] = useState<any>();
    const [show, setShow] = useState(false);



    useImperativeHandle(ref, () => ({ showQuestion: (q: any, num: number) => { setQuestion(q); setNumber(num); } }));



    const close = () => { setShow(false); setChoice(undefined); setQuestion(undefined); onClose(); };

    const isCorrect = (answer: NaTypes.Answer, i: number): JSX.Element => {
        if (!choice) return <div className="choice-default">{i + 1}</div>;

        const isAnswerCorrect = answer.isCorrect;
        const isSelectedIncorrect = answer.id === choice && isAnswerCorrect === false;
        const isSelectedCorrect = answer.id === choice && isAnswerCorrect === true;
        const isOtherCorrect = answer.id !== choice && isAnswerCorrect === true;

        return (
            <div className={`choice-default ${isSelectedIncorrect ? 'incorrect' : isSelectedCorrect || isOtherCorrect ? 'correct' : ''}`}>
                {i + 1}
            </div>
        );
    };

    const renderAnswerList = (answerList: any) => {
        return answerList.map((a: any, i: any) => (
            <div key={i} onClick={() => !choice && setChoice(a.id)} className="col-12 col-md-6 can-click">
                <div className="d-flex align-items-center p-0">
                    {isCorrect(a, i)}
                    {a.imagePath ? (
                        <div className="image-choice-layout">
                            <p className="m-0"><Latex>{a.value ?? ''}</Latex></p>
                            <img className="image-choice w-100" src={a.imagePath} alt="" />
                        </div>
                    ) : (
                        <p className="answer-text pl-2 m-0 w-100" style={{ whiteSpace: "break-spaces" }}>
                            <Latex>{a.value ?? ''}</Latex>
                        </p>
                    )}
                </div>
            </div>
        ));
    };



    useEffect(() => { setChoice(undefined); setShow(true); }, [question]);

    useEffect(() => {
        if (choice) {
            const autoClose = setTimeout(() => { setShow(false); onClose(); }, 2000);
            return () => clearTimeout(autoClose);
        }
    }, [choice]);

    useEffect(() => {
        if (choice) {
            const answer = question!.answerList.find((answer: { id: string }) => answer.id === choice);
            if (answer) {
                const { id: questionId, value: questionText, imagePath: questionImage } = question;
                const { value: answerText, imagePath: answerImage, isCorrect } = answer;
                const subject = subjectState.subjects.find((s) => s.subjectId === chapter.subject);

                dispatch(isCorrect ? showCorrect() : showIncorrect());

                if (subject) {
                    MixpanelTracking.questionAnswered(
                        chapter, subject.descriptionTh, questionId, questionText, questionImage, answerText, answerImage, isCorrect
                    );
                }
            }
        }
    }, [choice, question, chapter, dispatch, subjectState]);



    if (!show || !question) return null;

    return (
        <div className="question-bg">
            <div className="row h-100 m-0">
                <div className="col-6 h-100 p-2">
                    <div className="question-name-layout h-100">
                        <div className="number-question">
                            <p>{number}</p>
                        </div>
                        <p className="question-text">
                            <Latex>{question.value ?? ''}</Latex>
                        </p>
                        {ImageUtility.isImage(question.imagePath) && (
                            <div className="question-image">
                                <img style={{ maxHeight: "30vh", maxWidth: "20vw" }} src={question.imagePath} alt="" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-6 h-100 p-2 d-flex align-items-center">
                    <img className="quiz-close can-click" src={closeImg} alt="" onClick={close} />
                    <div className="row h-100 align-content-center m-0">
                        {renderAnswerList(question.answerList)}
                    </div>
                </div>
            </div>
        </div>
    );
});

QuizPopUp.displayName = 'QuizPopUp';
export default QuizPopUp;
