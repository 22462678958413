import { sign } from 'jsonwebtoken';
import { SignOptions } from 'jsonwebtoken';

const jwtSecret = process.env.REACT_APP_JWT_SECRET;

const generateToken = (id: string, subject: string): string => {

    if (!jwtSecret) throw new Error("JWT_SECRET is not defined in environment variables");
    const jwtOptions: SignOptions = { issuer: 'webnaschool', algorithm: 'HS256', noTimestamp: true, jwtid: id, subject };

    return sign({}, Buffer.from(jwtSecret), jwtOptions);
};

export { generateToken };
