import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti';

import './LiveStreamPage.css';
import SplitPane from 'react-split-pane';
import Chat from '../../components/chat/Chat';

import getPerfectScore from './utility-components/getPerfectScore';
import getRankingScore from './utility-components/getRankingScore';

import LiveStreamPageVideo from './page-components/LiveStreamPageVideo';
import LiveStreamPageAnnouncementPin from './page-components/LiveStreamPageAnnouncementPin';
import LiveStreamPageTitle from './page-components/LiveStreamPageTitle';
import LiveStreamPagePdf from './page-components/LiveStreamPagePdf';
import LiveStreamPagePaymentOffer from './page-components/LiveStreamPagePaymentOffer';
import LiveStreamPagePaymentConfirm from './page-components/LiveStreamPagePaymentConfirm';
import LiveStreamPagePerfectScore from './page-components/LiveStreamPagePerfectScore';
import LiveStreamPageRankingScore from './page-components/LiveStreamPageRankingScore';
import LiveStreamPageViewers from './page-components/LiveStreamPageViewers';

import { FirebaseContext } from '../../firebase/Firebase';
import { recordStudyTime } from '../../api/VideoOnDemand';
import { useInterval } from '../../utility';
import { generateToken } from '../../utility/Token';
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { clearAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';
import { liveStreamSelect, fetchLiveStreamTableList, fetchLiveStreamById } from '../../stores/live-stream-table-list/LiveStreamTableListState';



const LiveStreamPage = () => {

    const dispatch = useDispatch();
    const { id } = useParams<{ id?: string }>();
    const { database } = useContext(FirebaseContext);

    const chatComponent = useRef<ChatRef>(null);
    const viewerCountSet = useRef(new Set());

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const liveStream = useSelector((state: NaTypes.Store) => state.liveStreamTableList);
    const profileState = useSelector((state: NaTypes.Store) => state.profile);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [canSend, setCanSend] = useState<boolean>(true);
    const [isShowLiveQuizButton, setIsShowLiveQuizButton] = useState<boolean>(false);
    const [notificationCheck, setNotification] = useState<boolean>(false);
    const [showConfirmPayment, setShowConfirmPayment] = useState<boolean>(false);

    const [countStayingTime, setCountStayingTime] = useState<number>(0);
    const [stayTime, setStayTime] = useState<number>(0);
    const [viewerCount, setViewerCount] = useState<number>(0);

    const [invitedUserIDs, setInvitedUserIDs] = useState<Array<number>>([]);
    const [videoList, setVideoList] = useState<Array<any>>([]);

    const [exited, setExitLiveStream] = useState<boolean>(false);
    const [haveJoined, setHaveJoined] = useState<boolean>(false);
    const [streamClose, setStreamClose] = useState<boolean>(false);
    const [joinTime, setJoinTime] = useState<string>("");

    const [resizing, setResizing] = useState<boolean>(false);
    const [splitAxis, setSplitAxis] = useState<'vertical' | 'horizontal'>();
    const [splitSize, setSplitSize] = useState<'70%' | '0' | ''>();

    const [announcementPinOn, setAnnouncementPinOn] = useState<boolean>(false);
    const [announcementPin, setAnnouncementPin] = useState<string>("");

    const [studentPerfectScore, setStudentPerfectScore] = useState<Array<any>>([]);
    const [perfectScoreShow, setPerfectScoreShow] = useState<boolean>(false);
    const [perfectScoreStatus, setPerfectScoreStatus] = useState<boolean>(false);
    const [chatBlurStatus, setChatBlurStatus] = useState<boolean>(false);
    const [chatMuteStatus, setChatMuteStatus] = useState<boolean>(true);

    const [studentRankingScore, setStudentRankingScore] = useState<Array<any>>([]);
    const [rankingScoreShow, setRankingScoreShow] = useState<boolean>(false);
    const [rankingScoreStatus, setRankingScoreStatus] = useState<boolean>(false);
    const [rankingAmount, setRankingAmount] = useState<number>(3);

    const aid = accessData.accountId ? accessData.accountId : '';
    const aidNumber = parseInt(aid, 10);
    const url = window.location.href;

    const senderProfile = accessData.account ? {
        fullname: accessData.account.fullname,
        nickname: accessData.account.login,
        imageUrl: profileState.profile?.normalPhotoUrl
    } : null;

    let showPayment = showConfirmPayment;



    if (accessData.accessToken && !transaction.isPremium && liveStream.currentLS?.isFree == 0) showPayment = true;

    if (!database) throw new Error('Database is not available');
    const databaseRef = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${id}/`);



    const fetchAndUpdateMessages = async () => {
        await fetchMessageListAll(databaseRef);
        await fetchMessageList(databaseRef);
        chatComponent.current?.scrollToBottom();
    };

    const fetchMessageList = async (ref: firebase.database.Reference) => {
        try {
            const snapshot = await ref.orderByKey().limitToLast(250).once('value');
            const messageList = Object.keys(snapshot.val() || {}).map((key) => {
                const vals = snapshot.val()[key];
                const date = new Date(vals.createdTime * 1000);
                return {
                    key, self: accessData.accountId === vals.Id.toString(), message: vals.Message,
                    userId: vals.Id.toString(), userName: vals.UserName, userImageUrl: vals.UrlProfile, datetime: vals.createdTime,
                    datetimeDisplay: moment(date).format('HH:mm'), type: vals.type,
                    admin: accessData.accountId === process.env.REACT_APP_ADMIN_ACCOUNT
                };
            });
            chatComponent?.current?.fetchMessageList(messageList);

        } catch (error) {
            console.error("Error fetching message list:", error);
        }
    };

    const fetchMessageListAll = async (ref: firebase.database.Reference) => {
        try {
            const snapshot = await ref.orderByKey().once('value');
            const data = snapshot.val() || {};

            const messageList = Object.entries(data).map(([, vals]) => {
                const value = vals as { Id: number; type: string };
                return { userId: value.Id.toString(), type: value.type };
            });

            const canInsertJoin =
                accessData?.accountId && database &&
                !messageList.some(m => m.type === "JOIN" && m.userId === accessData.accountId) &&
                liveStream.currentLS?.actualStartTime;

            if (canInsertJoin) setHaveJoined(true);

            viewerCountSet.current = new Set(messageList.map(m => m.userId));
            setViewerCount(viewerCountSet.current.size);

        } catch (error) {
            console.error("Error fetching message list:", error);
        }
    };

    const handleResizeScreen = () => {
        if (window.innerWidth < 1024) {
            setSplitAxis('horizontal'); setSplitSize('');
        } else {
            setSplitAxis('vertical'); setSplitSize('70%');
        }
    };

    const handleNewMessage = (snapshot: firebase.database.DataSnapshot) => {
        const val = snapshot.val();
        const key = snapshot.key;
        if (val && key) {
            const date = new Date(val.createdTime * 1000);

            chatComponent.current?.addMessage({
                key: key, self: accessData.accountId === val.Id.toString(), message: val.Message,
                userId: val.Id.toString(), userName: val.UserName, userImageUrl: val.UrlProfile,
                datetime: val.createdTime, datetimeDisplay: moment(date).format('HH:mm'), type: val.type,
                admin: accessData.accountId === process.env.REACT_APP_ADMIN_ACCOUNT
            });

            viewerCountSet.current.add(val.Id.toString());
            setViewerCount(viewerCountSet.current.size);
        }
    };

    const handleRemoveMessage = (snapshot: firebase.database.DataSnapshot) => {
        const val = snapshot.val();
        const key = snapshot.key;
        if (val && key) chatComponent.current?.deleteMessage(key);
    };

    const insertJoinMessage = async () => {
        if (!accessData || !accessData.accountId || !liveStream.currentLS ||
            !liveStream.currentLS.actualStartTime || !haveJoined || !database) return;

        const newData = {
            FullName: accessData.account.fullname, Id: parseInt(accessData.accountId), Message: `${accessData.account.login} joined`,
            Uid: uuidv1().replace(/-/gi, '').toUpperCase(), UrlProfile: profileState.profile?.normalPhotoUrl,
            UserName: accessData.account.login, createdTime: Math.round(Date.now() / 1000), type: "JOIN"
        };

        try {
            await databaseRef.push().update(newData);
            chatComponent.current?.scrollToBottom();
        } catch (error) {
            console.error('Error updating database:', error);
        }
    };

    const pushMessage = (msg: string) => {
        if (canSend) {
            if (msg.trim().length > 0) {
                databaseRef.push().update({
                    FullName: accessData.account.fullname, Id: parseInt(accessData.accountId!), Message: msg,
                    Uid: uuidv1().replace(/-/gi, '').toUpperCase(), UrlProfile: profileState.profile?.normalPhotoUrl,
                    UserName: accessData.account.login, createdTime: Math.round(Date.now() / 1000), type: "CHAT"
                });
                chatComponent.current?.scrollToBottom();
                if (liveStream.currentLS) {
                    MixpanelTracking.commendLiveStream(
                        liveStream.currentLS.subjectId, liveStream.currentLS.subjectDescriptionTh, liveStream.currentLS.id,
                        liveStream.currentLS.subject, liveStream.currentLS.grade, accessData.account.grade, msg
                    );
                }
                setCanSend(false);
            }
        } else {
            toast.warning("คุณส่งข้อความเร็วเกินไป กรุณารอ " + liveStream.currentLS?.slowMode + " วินาที");
        }
    };

    const deleteMessage = (key: string) => {
        if (database) {
            const ref = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${id}/${key}`);
            ref.remove();
        }
    };

    const checkNotification = async () => {
        if (!accessData.accessToken || !accessData.accountId) return;
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/notification?ls_id=${id}&user_id=${accessData.accountId}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId, accessData.accessToken),
                }
            });
            const result = await response.json();
            const notification = result.data;

            setNotification(notification && notification.length > 0);
        } catch (err) {
            console.log(err);
        }
    };

    const notificationLS = async (lsId: string = "") => {
        if (!accessData.accessToken || !accessData.accountId) return dispatch(requestLogin());
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/insert/notification`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId, accessData.accessToken),
                },
                body: JSON.stringify({
                    "ls_id": lsId,
                    "user_id": accessData.accountId
                }),
            });

            setNotification(true);

            if (liveStream.currentLS) {
                MixpanelTracking.lsBookmark(
                    liveStream.currentLS.subjectId, liveStream.currentLS.subjectDescriptionTh, liveStream.currentLS.id,
                    liveStream.currentLS.subject, liveStream.currentLS.grade, accessData.account.grade
                );
            }
            return await response.json();

        } catch (err) {
            console.log(err);
        }
    };

    const unNotificationLS = async (lsId: string = "") => {
        if (!accessData.accessToken || !accessData.accountId) return dispatch(requestLogin());
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/delete/notification`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId, accessData.accessToken),
                },
                body: JSON.stringify({
                    "ls_id": lsId,
                    "user_id": accessData.accountId
                }),
            });

            setNotification(false);
            return await response.json();

        } catch (err) {
            console.log(err);
        }
    };

    const getAnnouncementBoard = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/setting`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            const announcementBoard = result.data;

            if (announcementBoard?.pin) {
                setAnnouncementPinOn(announcementBoard.pin.enable);
                setAnnouncementPin(announcementBoard.pin.text);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleLiveStreamTracking = async () => {
        try {
            if (!liveStream.currentLS) return;

            const utcOffset = 7 * 60;
            const now = moment.utc();
            const bangkokTime = now.clone().add(utcOffset, 'minutes');
            const time = bangkokTime.format('HH:mm');

            if (!joinTime) {
                setJoinTime(time);
                MixpanelTracking.joinLiveStream(
                    liveStream.currentLS.subjectId, liveStream.currentLS.subjectDescriptionTh, liveStream.currentLS.id,
                    liveStream.currentLS.subject, liveStream.currentLS.grade, liveStream.currentLS.type,
                    accessData.account.grade, liveStream.currentLS.startTime, liveStream.currentLS.endTime, time
                );
            } else if (streamClose && joinTime && !exited) {
                setExitLiveStream(true);
                MixpanelTracking.exitLiveStream(
                    liveStream.currentLS.subjectId, liveStream.currentLS.subjectDescriptionTh, liveStream.currentLS.id,
                    liveStream.currentLS.subject, liveStream.currentLS.grade, 'Lesson Finished',
                    accessData.account.grade, joinTime, time, stayTime
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkAutoPlay = async () => {
        if (liveStream.currentLS?.type === "Auto" && liveStream.currentLS.urlVideo && liveStream.currentLS.videoId) {
            try {
                const res = await getVimeo(liveStream.currentLS.videoId);
                setVideoList(res.files);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getVimeo = async (id: string | undefined): Promise<any> => {
        try {
            const res = await fetch(`https://api.vimeo.com/videos/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
                    'Content-Type': 'application/json',
                },
            });
            return await res.json();
        } catch (e) {
            console.log(e);
        }
    };

    const fetchInvitedUserIDs = async () => {
        try {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': generateToken(accessData.accountId!, accessData.accessToken!),
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/user/invitational/ids`, {
                method: 'GET',
                headers: headers,
            });

            if (response.ok) {
                const responseData = await response.json();
                const newInvitedUserIDs = responseData.data.map((item: { id: number }) => item.id);
                setInvitedUserIDs(newInvitedUserIDs);
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching invited user IDs:', error);
        }
    };

    const onClickDownloadDocument = (url: string, documentType: string) => {
        if (!accessData.accountId || !accessData.accessToken) return dispatch(requestLogin());
        window.open(url);
        if (liveStream.currentLS) {
            const { subjectId, subjectDescriptionTh, id, subject, grade } = liveStream.currentLS;
            MixpanelTracking.downloadDocumentOnLiveStream(
                subjectId, subjectDescriptionTh, id,
                subject, grade, accessData.account.grade, documentType
            );
        }
    };

    const handleExit = async (selfExitStayTime: number) => {
        if (joinTime && accessData.accountId) {
            try {
                const token = generateToken(accessData.accountId, accessData.accessToken!);
                const params = new URLSearchParams();
                params.append('access_token', token);
                params.append('account_id', accessData.accountId);

                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v6/method/get.current.date?${params.toString()}`, {
                    method: 'GET',
                });
                const data = await response.json();

                if (liveStream.currentLS) {
                    MixpanelTracking.exitLiveStream(
                        liveStream.currentLS.subjectId, liveStream.currentLS.subjectDescriptionTh, liveStream.currentLS.id,
                        liveStream.currentLS.subject, liveStream.currentLS.grade, 'Press Back',
                        accessData.account.grade, joinTime, data.time, selfExitStayTime
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
    };



    useInterval(async () => {
        if (liveStream.currentLS?.id && !streamClose) {
            try {
                const ls = await fetchLiveStreamById(liveStream.currentLS.id, accessData);

                if (ls.error_code === 101) {
                    dispatch(clearAccessData());
                    dispatch(resetLessonSet());
                } else if (ls.status === '1') {
                    setStreamClose(true);
                    const currentIndex = liveStream.list.findIndex((d) => d.id === id);
                    if (currentIndex + 1 < liveStream.list.length) {
                        dispatch(liveStreamSelect(liveStream.list[currentIndex + 1]));
                    }
                }

                setRankingAmount(ls.rankingAmount);
                setPerfectScoreStatus(ls.perfectScoreStatus);
                setRankingScoreStatus(ls.rankingScoreStatus);
                setChatBlurStatus(ls.blurStatus);
                setChatMuteStatus(ls.muteStatus);

            } catch (error) {
                console.log(error);
            }
        }
    }, 10000);

    useInterval(() => {
        const increment = process.env.NODE_ENV === 'production' ? 1 : 60;
        if (liveStream.currentLS?.actualStartTime) setCountStayingTime(prev => prev + increment);
    }, 1000);



    useEffect(() => {
        fetchAndUpdateMessages();
        handleResizeScreen(); window.addEventListener('resize', handleResizeScreen);
        dispatch(fetchLiveStreamTableList(accessData.accountId || null));
        checkNotification(); getAnnouncementBoard(); MixpanelTracking.init();
        return () => { window.removeEventListener('resize', handleResizeScreen) };
    }, []);

    useEffect(() => {
        const currentTimestamp = Math.round(Date.now() / 1000);
        databaseRef.orderByChild('createdTime').startAt(currentTimestamp).on('child_added', handleNewMessage);
        return () => databaseRef.off('child_added', handleNewMessage);
    }, []);

    useEffect(() => {
        databaseRef.on('child_removed', handleRemoveMessage);
        return () => databaseRef.off('child_removed', handleRemoveMessage);
    }, []);

    useEffect(() => { insertJoinMessage() }, [haveJoined]);

    useEffect(() => {
        const ls = liveStream.list.find((d) => d.id === id);
        dispatch(liveStreamSelect(ls));
    }, [liveStream.list]);

    useEffect(() => {
        checkAutoPlay();
        if (liveStream.currentLS) {
            setChatBlurStatus(liveStream.currentLS.blurStatus);
            setChatMuteStatus(liveStream.currentLS.muteStatus);
        }
        if (liveStream.currentLS?.status === '1') setStreamClose(true);
        if (accessData.accountId && accessData.accessToken) {
            const timeout = setInterval(() => { setStayTime(prevTime => prevTime + 1); }, 1000);
            if (liveStream.currentLS) handleLiveStreamTracking();
            return () => {
                clearInterval(timeout); handleExit(stayTime);
            };
        }
    }, [liveStream.currentLS]);

    useEffect(() => {
        const hasLiveStreamList = liveStream.list.length > 0;
        const invitedUsersExist = invitedUserIDs.length > 0;
        const aidNotIncluded = !invitedUserIDs.includes(aidNumber);

        if (hasLiveStreamList) {
            liveStream.currentLS = liveStream.list.find(ls => ls.id === id);

            if (!liveStream.currentLS) window.location.href = `${process.env.REACT_APP_WEB_URL}`;

            if (liveStream.currentLS?.invitational == 1) {
                fetchInvitedUserIDs();
                if ((invitedUsersExist && aidNotIncluded) || !aid) window.location.href = `${process.env.REACT_APP_WEB_URL}`;
            }
        }
    }, [liveStream.currentLS, invitedUserIDs]);

    useEffect(() => {
        if (liveStream.currentLS) {
            const timeout = setInterval(() => setCanSend(true), liveStream.currentLS?.slowMode * 1000);
            return () => clearInterval(timeout);
        }
    }, [canSend]);

    useEffect(() => {
        if (perfectScoreStatus) {
            setIsShowLiveQuizButton(false);
            getPerfectScore({ accessData, liveStream, setStudentPerfectScore, setPerfectScoreShow });
        } else {
            setPerfectScoreShow(false);
        }
    }, [perfectScoreStatus]);

    useEffect(() => {
        if (rankingScoreStatus) {
            setIsShowLiveQuizButton(false);
            getRankingScore({ accessData, liveStream, rankingAmount, setStudentRankingScore, setRankingScoreShow });
        } else {
            setRankingScoreShow(false);
        }
    }, [rankingScoreStatus, rankingAmount]);

    useEffect(() => {
        if (countStayingTime >= 20 * 60 && liveStream.currentLS) {
            recordStudyTime(accessData, liveStream.currentLS.subjectId, countStayingTime);
            setCountStayingTime(0);
        }
    }, [countStayingTime]);



    if (liveStream.state === 'PENDING') return <>Loading</>;

    return (
        <>
            <div className="row livestream-page-container" style={perfectScoreShow || rankingScoreShow ? { position: "absolute" } : {}}>

                <aside className="order-2 order-sm-1 col-12 col-sm-5 col-md-4 col-lg-3 p-0 chat-content">
                    <Chat
                        ref={chatComponent} senderProfile={senderProfile} send={pushMessage} delete={deleteMessage}
                        blur={chatBlurStatus} mute={chatMuteStatus}
                    />
                </aside>

                <section className="h-100 order-1 order-sm-2 col-12 col-sm-7 col-md-8 col-lg-9 no-padding">
                    <SplitPane
                        size={splitSize} split={splitAxis}
                        onDragStarted={() => setResizing(true)} onDragFinished={() => setResizing(false)}
                    >
                        <div className="video-section-container" style={{ position: "relative" }}>
                            <LiveStreamPageVideo
                                showPayment={showPayment} isShowLiveQuizButton={isShowLiveQuizButton} perfectScoreShow={perfectScoreShow}
                                rankingScoreShow={rankingScoreShow} streamClose={streamClose} videoList={videoList}
                                setIsShowLiveQuizButton={setIsShowLiveQuizButton} setPerfectScoreShow={setPerfectScoreShow}
                                setRankingScoreShow={setRankingScoreShow} setStreamClose={setStreamClose}
                            />

                            <div className="livestream-page-number-tags">
                                {liveStream.currentLS?.urlVideo ? (
                                    <div className="live-stream-broadcasting-text">
                                        <svg height="10" width="10">
                                            <ellipse cx="5" cy="5" rx="5" ry="5" style={{ fill: '#ffffff' }} />
                                        </svg>
                                        <span style={{ marginLeft: '5px' }}>กำลังไลฟ์สด</span>
                                    </div>
                                ) : null}

                                <LiveStreamPageViewers
                                    liveStreamUrl={liveStream.currentLS?.urlVideo || ''}
                                    streamClose={!streamClose} viewerCount={viewerCount}
                                />
                            </div>

                            <LiveStreamPageAnnouncementPin
                                announcementPin={announcementPin} announcementPinOn={announcementPinOn}
                                setAnnouncementPinOn={setAnnouncementPinOn}
                            />

                            <LiveStreamPageTitle
                                gradeState={gradeState} liveStream={liveStream} notificationCheck={notificationCheck} url={url}
                                notificationLS={notificationLS} onClickDownloadDocument={onClickDownloadDocument}
                                unNotificationLS={unNotificationLS}
                            />
                        </div>

                        <LiveStreamPagePdf resizing={resizing} />

                    </SplitPane>
                </section>
            </div>

            <LiveStreamPagePaymentOffer
                showPayment={showPayment} transaction={transaction}
                setShowConfirmPayment={setShowConfirmPayment}
            />

            <LiveStreamPagePaymentConfirm
                showPayment={showPayment} transaction={transaction}
                setShowConfirmPayment={setShowConfirmPayment}
            />

            <div className="wraper" />
            <Confetti hidden={!perfectScoreShow} numberOfPieces={100} />
            <Confetti hidden={!rankingScoreShow} numberOfPieces={100} />

            <LiveStreamPagePerfectScore
                perfectScoreShow={perfectScoreShow} studentPerfectScore={studentPerfectScore}
                setPerfectScoreShow={setPerfectScoreShow}
            />

            <LiveStreamPageRankingScore
                liveStream={liveStream} rankingAmount={rankingAmount}
                rankingScoreShow={rankingScoreShow} studentRankingScore={studentRankingScore}
                setRankingScoreShow={setRankingScoreShow}
            />

            <ToastContainer
                autoClose={liveStream.currentLS?.slowMode ? liveStream.currentLS?.slowMode * 1000 : 3000} position="bottom-left"
                hideProgressBar={false} pauseOnHover={false} rtl={false}
                closeOnClick draggable newestOnTop pauseOnFocusLoss
            />
        </>
    )
};

export default LiveStreamPage;
