import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { close } from '../../stores/correct-and-incorrect-overlay/CorrectAndIncorrectState';
import '../../App.css';
import Lottie from 'lottie-web';
import correct from '../../lottie/correct.json';
import incorrect from '../../lottie/incorrect.json';



const CorrectAndIncorrectOverlay = () => {

    const dispatch = useDispatch();
    const state = useSelector((s: NaTypes.Store) => s.correctAndIncorrectOverlay);
    const container = useRef<HTMLDivElement>(null);



    useEffect(() => {
        const json = state.correct ? correct : incorrect;
        const anim = Lottie.loadAnimation({
            animationData: JSON.parse(json),
            container: container.current as Element,
            autoplay: false,
            loop: false,
        });

        const completeHandler = () => {
            anim.removeEventListener('complete', completeHandler);
            anim.setDirection(-1);
            anim.play();
            dispatch(close());
        };

        anim.addEventListener('complete', completeHandler);
        anim.play();
        return () => {
            anim.removeEventListener('complete', completeHandler);
            anim.destroy();
        };
    }, [dispatch, state.correct, state.show]);



    return state.show ? (
        <div className="ci-overlay" onClick={() => dispatch(close())}>
            <div ref={container} className="ci-overlay-container"></div>
        </div>
    ) : null;
};

export default CorrectAndIncorrectOverlay;
