import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import userImage from '../../images/user/user.svg';
import { uploadProfilePhoto } from '../../api/Upload';
import { useSelector } from 'react-redux';

interface Props {
  default?: string
}

const UploadProfile = forwardRef((props: Props, ref) => {
  const accessData = useSelector((selector: NaTypes.Store) => selector.authenticate);
  const [image, setImage] = useState(props.default);
  const uploader = useRef<HTMLInputElement>(null);


  useEffect(() => {
    setImage(props.default);
  }, [props]);

  useImperativeHandle(ref, (): UploadProfileRefs => {
    return {
      upload: () => {
        if (uploader.current?.files && uploader.current.files.length > 0) {
          return uploadProfilePhoto(accessData, uploader.current.files[0]);
        } else {
          return Promise.resolve();
        }
      },
    };
  });

  return (
    <div className="upload-profile-container"
      onClick={() => {
        uploader?.current?.click();
      }}
    >
      <img style={{ objectFit: 'cover' }} src={image ? image : userImage} alt="" width="150px" height="150px" />
      <input ref={uploader} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
          const reader = new FileReader();
          reader.onload = (res) => {
            setImage(res.target?.result?.toString());
          };
          reader.readAsDataURL(e.currentTarget.files[0]);
        }
      }} hidden />
      <div className="upload-profile-edit-container">
        <div className="upload-profile-edit-title-container">
          <p style={{ color: 'white', marginTop: '4px', fontWeight: 'bold' }}>Edit</p>
        </div>
      </div>
    </div>
  );
});

UploadProfile.displayName = 'UploadProfile';

export default UploadProfile;
