import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchGrades } from './stores/grade/GradeState';
import { fetchPlaybackTypes } from './stores/playbackType/PlaybackTypeState';
import { fetchProfile } from './stores/profile/ProfileState';
import { fetchSubjects } from './stores/subject/SubjectState';
import { reloadTransaction } from './stores/transaction/Transaction';
import { setLessonSet } from './stores/lesson-list/LessonSetListState';
import { setSdkFacebook } from './stores/facebookModule/FbModule';

import CorrectAndIncorrectOverlay from './components/correct-and-incorrect-overlay/CorrectAndIncorrectOverlay';
import HeaderBar from './components/header-bar/HeaderBar';
import NaLogin from './components/na-login/NaLogin';
import PopUp from './components/pop-up/PopUp';
import Promote from './components/promote/Promote';
import TitleConfig from './components/title-config/TitleConfig';

import EditProfilePage from './pages/edit-profile/EditProfile';
import Exam from './pages/exam/Exam';
import ExamAnswer from './pages/exam-answer/ExamAnswer';
import ExamAnswerOld from './pages/exam-answer-old/ExamAnswerOld';
import ExamList from './pages/exam-list/ExamList';
import ExamResult from './pages/exam-result/ExamResult';
import JoinSchoolPage from './pages/join-school/JoinSchool';
import LivePage from './pages/live-stream/LiveStreamPage';
import LiveStreamHistoryPage from './pages/live-stream-history/LiveStreamHistoryPage';
import LiveStreamShowList from './pages/live-stream-show-list/LiveStreamShowList';
import LiveStreamShowTopic from './pages/live-stream-show-topic/LiveStreamShowTopic';
import LiveStreamShowTopicList from './pages/live-stream-show-topic-list/LiveStreamShowTopicList';
import MainPage from './pages/main/MainPage';
import Mobile from './pages/mobile/Mobile';
import Mobile1 from './pages/mobile-1/Mobile1';
import PayExtension from './pages/pay-extension/PayExtension';
import PayExtensionPremium from './pages/pay-extension-premium/PayExtensionPremium';
import PayExtensionPremiumPlus from './pages/pay-extension-premium-plus/PayExtensionPremiumPlus';
import PayMobile from './pages/pay-mobile/PayMobile';
import PayMobileGuest from './pages/pay-mobile-guest/PayMobileGuest';
import PayMobilePremium from './pages/pay-mobile-premium/PayMobilePremium';
import PaymentPage from './pages/payment/PaymentPage';
import PaymentHistoryPage from './pages/payment-history/PaymentHistory';
import PaymentManagementPage from './pages/payment-management/PaymentManagement';
import QuarterExamPage from './pages/quater-exam/QuarterExam';
import VODPage from './pages/vod/VODPage';
import Page404 from './pages/404/Page404';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';



const App = () => {
    const dispatch = useDispatch();

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const fbModule = useSelector((state: NaTypes.Store) => state.facebookModule);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';



    if (at !== '' || aid !== '') {
        dispatch(reloadTransaction(at, aid));
        dispatch(fetchProfile(at, aid));
    }

    if (gradeState.grades.length > 0 && subjectState.subjects.length > 0) {
        dispatch(setLessonSet(gradeState.grades, subjectState.subjects));
    }

    if (!fbModule.sdk) {
        (window as any).fbAsyncInit = function () {
            (window as any).FB.init({
                appId: '1104706589621011',
                autoLogAppEvents: true,
                cookie: true,
                xfbml: true,
                version: 'v22.0',
            });
            dispatch(setSdkFacebook(true));
        };

        (function (d, s, id) {
            const fjs = d.getElementsByTagName<any>(s)[0];
            if (d.getElementById(id)) return;
            const js = d.createElement<any>(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }



    useEffect(() => {
        dispatch(fetchGrades());
        dispatch(fetchSubjects());
        dispatch(fetchPlaybackTypes());
    }, [dispatch]);



    return (
        <>
            <CorrectAndIncorrectOverlay />
            <NaLogin />
            <PopUp />
            <BrowserRouter>
                <TitleConfig />
                <HeaderBar />
                <Promote />
                <Switch>
                    <Route exact path="/" component={MainPage} />
                    <Route exact path="/sign_in" component={MainPage} />
                    <Route path="/examstart/:grade/:quarter/:id" component={Exam} />
                    <Route path="/examanswer/:grade/:quarter/:id/:examId" component={ExamAnswerOld} />
                    <Route path="/examanswer/:grade/:quarter/:id" component={ExamAnswer} />
                    <Route path="/exam-list" component={ExamList} />
                    <Route path="/examresult/:grade/:quarter" component={ExamResult} />
                    <Route path="/exam/:grade/:quarter" component={QuarterExamPage} />
                    <Route path="/join/school/:token" component={JoinSchoolPage} />
                    <Route path="/live/:id" component={LivePage} />
                    <Route path="/livehistory/:id" component={LiveStreamHistoryPage} />
                    <Route path="/livehistory" component={LiveStreamShowList} />
                    <Route path="/mobile" component={Mobile} />
                    <Route path="/mobile-1" component={Mobile1} />
                    <Route path="/pay/:packageId?/:token?" component={PaymentPage} />
                    <Route path="/pay-extension" component={PayExtension} />
                    <Route path="/pay-extension-premium" component={PayExtensionPremium} />
                    <Route path="/pay-extension-premium-plus" component={PayExtensionPremiumPlus} />
                    <Route path="/pay-mobile" component={PayMobile} />
                    <Route path="/pay-mobile-guest" component={PayMobileGuest} />
                    <Route path="/pay-mobile-premium" component={PayMobilePremium} />
                    <Route path="/payment-history" component={PaymentHistoryPage} />
                    <Route path="/payment-management" component={PaymentManagementPage} />
                    <Route path="/playbacktopic/:topic" component={LiveStreamShowTopic} />
                    <Route path="/playbacktopic" component={LiveStreamShowTopicList} />
                    <Route path="/profile/edit" component={EditProfilePage} />
                    <Route path="/video/:subject/:grade/:topic" component={VODPage} />
                    <Route component={Page404} />
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default App;
