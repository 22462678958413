import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChatInput from './ChatInput';
import Message from './Message';
import { toast } from 'react-toastify';
import { Transition } from 'react-spring/renderprops';
import './Chat.css';



const Chat = forwardRef<ChatRef, ChatProps>((props, ref) => {

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const [autoScrollButton, setAutoScrollButton] = useState<ChatAutoScrollButton>({ show: false });
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState<Array<MessageItem>>([]);
    const [lastScrollPos, setLastScrollPos] = useState<LastScrollPos>({ direction: '', lastScrollPos: 0 });
    const [showChatContent, setShowChatContent] = useState<boolean>(true);

    const aid = accessData.accountId ? accessData.accountId : '';
    const isAdmin = aid === process.env.REACT_APP_ADMIN_ACCOUNT;

    const messagesContainer = React.createRef<HTMLDivElement>();
    const messagesEndRef = React.createRef<HTMLDivElement>();



    const checkingAutoButton = (message: string) => {
        if (!autoScroll) setAutoScrollButton({ show: true, lastestText: `"${message}"` });
    };

    const send = () => {
        if (props.mute) {
            toast('คุณถูกปิดแชท กรุณาติดต่อ Admin');
        } else {
            props.send(message);
        }
        setMessage('');
    };

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesContainer.current?.scrollTo({ top: messagesContainer.current.scrollHeight });
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const currentScrollPos = event.currentTarget.scrollTop;
        const direction = lastScrollPos.lastScrollPos > currentScrollPos ? 'up' : 'down';
        setLastScrollPos({ direction, lastScrollPos: currentScrollPos });
    };

    const handleAutoScrollUp = () => {
        if (lastScrollPos.direction === 'up') setAutoScroll(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') send();
    };

    const handleAutoScrollButtonClick = () => {
        scrollToBottom();
        setAutoScroll(true);
        setAutoScrollButton({ ...autoScrollButton, show: false });
    };



    useImperativeHandle(ref, (): ChatRef => ({
        fetchMessageList: (messageList: MessageItem[]) => setMessageList(messageList),
        addMessage: (message: MessageItem) => {
            checkingAutoButton(message.message);
            setMessageList([...messageList, message]);
        },
        deleteMessage: (key: string) => setMessageList(messageList.filter((m) => m.key !== key)),
        scrollToBottom: () => {
            scrollToBottom();
            setAutoScroll(true);
            setAutoScrollButton({ ...autoScrollButton, show: false });
        },
    }));



    useEffect(() => { handleAutoScrollUp(); }, [lastScrollPos]);
    useEffect(() => { scrollToBottom(); }, [messageList, autoScroll]);



    return (
        <div className="chat-container">
            <div className="my-card chat-background">
                <div className="my-card bg-white chat-title-container">
                    <div className="chat-title-text rainbow_text_animated">แชต</div>
                    {props.blur && isAdmin && (
                        <div className="admin-blur-tag">○ กำลังเบลอ</div>
                    )}
                    <div
                        className={`toggle-chat-content chat-button-${showChatContent ? '' : 'rotate'}`}
                        onClick={() => setShowChatContent(!showChatContent)}>
                        ×
                    </div>
                </div>

                {showChatContent && (
                    <div ref={messagesContainer} className='chat-message-container' onScroll={handleScroll}>
                        {messageList.map((m, i) => (<Message key={i} message={m} deleteMessage={props.delete} blur={props.blur} />))}
                        <div ref={messagesEndRef} />
                    </div>
                )}

                {showChatContent && !props.blur && (<div className="chat-autoscolling-container">
                    <Transition
                        items={autoScrollButton.show}
                        from={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                        enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
                        leave={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                    >
                        {(show) => show && ((props) => (
                            <div
                                className="chat-autoscolling-button"
                                style={props}
                                onClick={handleAutoScrollButtonClick}
                            >
                                {autoScrollButton.lastestText}
                            </div>
                        ))}
                    </Transition>
                </div>)}

                {showChatContent && (<div className="chat-input-container">
                    <div className="chat-input-container-item" style={{ alignItems: 'unset' }}>
                        <ChatInput
                            senderProfile={props.senderProfile} message={message}
                            setMessage={setMessage} handleKeyDown={handleKeyDown} send={send}
                        />
                    </div>
                </div>)}
            </div>
        </div>
    );
});



Chat.displayName = 'Chat';

export default Chat;
