import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateToken } from '../../utility/Token';
import { fetchLiveQuizPercentageById } from '../../stores/live-quiz-percentage/LiveQuizPercentageState';
import LiveQuizQuestion from './LiveQuizQuestion';
import LiveQuizAnswer from './LiveQuizAnswer';
import './LiveQuizPopUp.css';

interface LiveQuizPopUpProps {
    questions?: LiveQuestion[];
    sendCertificate: () => Promise<void>;
}

const LiveQuizPopUp = forwardRef<LiveQuestionRefs, LiveQuizPopUpProps>(({ questions, sendCertificate }, ref) => {

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const liveQuizPercentage = useSelector((state: NaTypes.Store) => state.liveQuizPercentage);
    const dispatch = useDispatch();
    const [choice, setChoice] = useState<string>();
    const [lastQuestionId, setLastQuestionId] = useState<string | undefined>();
    const [question, setQuestion] = useState<LiveQuestion | undefined>();
    const [sendingSubmit, setSendingSubmit] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);



    useImperativeHandle(ref, (): LiveQuestionRefs => ({
        getCurrentQuestion: () => question,
        reset: () => { setShowAnswer(false); setChoice(''); },
        setQuestion: (q) => setQuestion(q),
        showQuestion: () => setShow(true),
        showAnswer: () => {
            if (question) dispatch(fetchLiveQuizPercentageById(question.id));
            setShowAnswer(true); setShow(true);
        },
    }));



    const submitAnswer = async (questionId: string, answerId: string) => {
        try {
            setSendingSubmit(true);
            await liveQuizSubmitAnswer(questionId, answerId);
            setChoice(answerId);
        } catch (error) {
            console.error(error);
        } finally {
            setSendingSubmit(false);
        }
    };

    const liveQuizSubmitAnswer = async (questionId: string, answerId: string) => {
        if (!accessData.accountId || !accessData.accessToken) return;

        const token = generateToken(accessData.accountId, accessData.accessToken);
        const params = new URLSearchParams({
            access_token: token, account_id: accessData.accountId, question_id: questionId, answer_id: answerId,
        });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v5/method/liveQuestion.submit`, {
            method: 'POST',
            body: params,
        });

        return response;
    };



    useEffect(() => {
        if (questions && questions.length > 0) setLastQuestionId(questions[questions.length - 1].id);
    }, [questions]);

    useEffect(() => {
        if (question && question.id === lastQuestionId && showAnswer) {
            sendCertificate();
            setLastQuestionId(undefined);
        };
    }, [question, lastQuestionId, showAnswer, sendCertificate]);



    return !show || !question ? null : (
        <div className="question-bg">
            <div className="row h-100 m-0" id="quiz-container">
                <LiveQuizQuestion question={question} setShow={setShow} />
                <LiveQuizAnswer
                    accessData={accessData} choice={choice} liveQuizPercentage={liveQuizPercentage} question={question}
                    sendingSubmit={sendingSubmit} setShow={setShow} showAnswer={showAnswer} submitAnswer={submitAnswer}
                />
            </div>
        </div>
    );
});

LiveQuizPopUp.displayName = 'LiveQuizPopUp';

export default LiveQuizPopUp;
