import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeString, toggleNotificationLS, checkNotification } from './LiveStreamTableListUtils';
import bookmark from '../../images/notification/BookMarkIcon.svg';
import bookmarkOn from '../../images/notification/bookMarkIconOn.png';



const LiveStreamTableListItem = (props: {
    liveStream: NaTypes.LiveStream,
    isSelected: boolean,
    isLive: boolean,
    onClick: () => void,
}) => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [notificationCheck, setNotification] = useState<boolean>(false);
    const dispatch = useDispatch();



    useEffect(() => {
        checkNotification(accessData, props.liveStream.id, setNotification);
    }, [props.liveStream]);



    return (
        <div className={`col-6 col-sm-6 col-lg-3 col-xl-2 live-stream-item ${props.isSelected ? 'select' : ''}`}>
            <div className="live-stream-item-date-title">
                {`${getTimeString(props.liveStream.startTime)} ${props.liveStream.runTime}`}
            </div>

            <div
                className={`live-stream-item-image ${props.isSelected ? 'select' : ''}`}
                onClick={() => {
                    checkNotification(accessData, props.liveStream.id, setNotification);
                    props.onClick();
                }}
            >
                <img
                    alt={props.liveStream.subject}
                    className={`live-stream-item-logo ${props.isSelected ? 'select' : ''}`}
                    src={props.liveStream.scheduleImagePath}
                />
                {props.liveStream.newUpdate && <div className="new-update">มาใหม่</div>}
            </div>

            {!props.liveStream.urlVideo ? (
                <div
                    style={{
                        border: notificationCheck ? '1px solid #FFCC00' : '1px solid #9F9F9F',
                        borderRadius: '6px',
                        paddingLeft: '5px',
                        marginTop: '5px',
                        cursor: 'pointer',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        display: 'flow-root',
                        paddingRight: notificationCheck ? '0px' : '5px',
                    }}
                    onClick={() => toggleNotificationLS(props.liveStream, !notificationCheck, accessData, dispatch, setNotification)}
                >
                    <div className='livestream-notification-text'>
                        {notificationCheck ? 'รับการแจ้งเตือนแล้ว' : 'รับการแจ้งเตือน'}
                        <div style={{ fontSize: 'small' }}>{`${props.liveStream.subscriber} คนรออยู่`}</div>
                    </div>
                    <img style={{ float: 'right', width: '23px', marginTop: '11px' }} src={notificationCheck ? bookmarkOn : bookmark} />
                </div>
            ) : null}

            <div className="live-stream-item-title">
                {props.liveStream.subject}
                <br />
                {props.liveStream.gradeDescriptionTh && (
                    <div className="video-tag-info video-tag-info-ls" style={{marginBottom: '15px'}}>
                        <p className="subject-name-ls">{`+ ${props.liveStream.gradeDescriptionTh}`}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiveStreamTableListItem
