import React from 'react';
import moment from 'moment';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LineShareButton, LineIcon } from "react-share";
import SlideIcon from '../../../images/document/slide-icon.svg';
import DownloadIcon from '../../../images/document/download.svg';
import BookmarkIcon from '../../../images/notification/BookMarkIcon.svg';
import BookmarkOnIcon from '../../../images/notification/bookMarkIconOn.png';



const LiveStreamPageTitle = (props: {
    liveStream: NaTypes.Store['liveStreamTableList'],
    url: string,
    notificationCheck: boolean,
    unNotificationLS: (lsId: string) => void,
    notificationLS: (lsId: string) => void,
    gradeState: { grades: { grade: string; descriptionTh: string }[] },
    onClickDownloadDocument: (url: string, fileType: string) => void,
}) => {

    const getTime = (startTime?: string, endTime?: string) => {
        return `${moment(startTime).add(7, 'hours').format('HH:mm')} - ${moment(endTime).add(7, 'hours').format('HH:mm')}`;
    };



    return (
        <div className="live-title-container">
            <div className="d-flex justify-content-between align-items-center">
                <div className="live-title-text" style={{ maxWidth: "580px" }}>
                    <h1 className="live-title-text rainbow_text_animated">{props.liveStream.currentLS?.subject}</h1>
                </div>

                <div>
                    <div style={{ textAlign: "center", paddingLeft: "12px", marginBottom: "5px" }}>
                        <FacebookShareButton
                            url={props.url}
                            quote={props.liveStream.currentLS?.subject}
                            className="share-icon"
                            title={props.liveStream.currentLS?.subject}
                        >
                            <FacebookIcon size={32} round path={props.url} />
                        </FacebookShareButton>
                        <TwitterShareButton
                            title={props.liveStream.currentLS?.subject}
                            url={props.url}
                            className="share-icon"
                            hashtags={["NockAcademy"]}
                        >
                            <TwitterIcon size={32} round path={props.url} />
                        </TwitterShareButton>
                        <LineShareButton title={props.liveStream.currentLS?.subject} url={props.url} className="share-icon">
                            <LineIcon size={32} round path={props.url} />
                        </LineShareButton>
                    </div>

                    {!props.liveStream.currentLS?.urlVideo ? (
                        props.notificationCheck ? (
                            <div
                                className="unnotify-button"
                                onClick={() => props.unNotificationLS(props.liveStream.currentLS?.id || '')}
                            >
                                <div style={{ float: "left" }}>รับการแจ้งเตือนแล้ว</div>{" "}
                                <img style={{ float: "right", width: "23px" }} src={BookmarkOnIcon} alt="" />
                            </div>
                        ) : (
                            <div
                                className="notify-button"
                                onClick={() => props.notificationLS(props.liveStream.currentLS?.id || '')}
                            >
                                <div style={{ float: "left" }}> รับการแจ้งเตือน </div>
                                <img style={{ float: "right", width: "23px" }} src={BookmarkIcon} alt="" />
                            </div>
                        )
                    ) : null}
                </div>
            </div>

            <div>{getTime(props.liveStream.currentLS?.startTime, props.liveStream.currentLS?.endTime)}</div>

            <div>
                {props.liveStream.currentLS?.subjectDescriptionTh} {props.liveStream.currentLS?.grade && props.gradeState.grades.find(s => s.grade === props.liveStream.currentLS?.grade)?.descriptionTh} ({props.liveStream.currentLS?.instructorName})
            </div>

            <div>{props.liveStream.currentLS?.description}</div>

            <div className="document-title-header-container">
                <span className="document-title-header rainbow_text_animated">เอกสารประกอบการเรียน</span>
            </div>

            {props.liveStream.currentLS?.slide && (
                <div className="row">
                    <div className="col-12 col-lg-6 document-download-container">
                        <div
                            className="document-download-button"
                            onClick={() =>
                                props.onClickDownloadDocument(
                                    `${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${props.liveStream.currentLS?.slide}`,
                                    'Slide'
                                )
                            }
                        >
                            <img className="document-thumbnail-icon" src={SlideIcon} alt="" />
                            <span className="document-download-text">สไลด์ประกอบการเรียน</span>
                            <img className="document-download-icon" src={DownloadIcon} alt="" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LiveStreamPageTitle;
