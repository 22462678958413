import React from 'react';
import { Dispatch } from 'redux';
import moment from 'moment';
import 'moment/locale/th';
import { generateToken } from '../../utility/Token';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { MixpanelTracking } from '../../utility/MixpanelTracking';



export const getDate = (startTime: string) => {
    const today = moment();
    const startMoment = moment(startTime);

    if (startMoment.isSame(today, 'date')) {
        return 'วันนี้';
    } else if (startMoment.isSame(today.clone().add(1, 'day'), 'date')) {
        return 'พรุ่งนี้';
    }

    return startMoment.locale('th').format('DD MMMM YYYY');
};



export const getTime = (startTime: string, endTime: string) =>
    `${moment(startTime).add(7, 'hours').format('HH:mm')} – ${moment(endTime).add(7, 'hours').format('HH:mm')}`;



export const seekTime = (liveStreamList: NaTypes.LiveStreamTableList) => {
    const { duration, startTime } = liveStreamList.currentLS || {};
    return duration && startTime
        ? ((new Date().getTime() - new Date(startTime).setHours(new Date(startTime).getHours() + 7)) / 1000) % duration
        : 0;
};



export const handleNotification = async (
    lsId: string = "",
    action: "insert" | "delete",
    accessData: NaTypes.Authenticate,
    dispatch: Dispatch<any>,
    setNotification: React.Dispatch<React.SetStateAction<boolean>>,
    liveStreamList: NaTypes.LiveStreamTableList,
) => {
    if (!accessData.accessToken) {
        dispatch(requestLogin());
        return;
    }

    const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/${action}/notification`;
    const requestBody = {
        "ls_id": lsId,
        "user_id": accessData.accountId!
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': generateToken(accessData.accountId!, accessData.accessToken!),
            },
            body: JSON.stringify(requestBody),
        });

        const responseData = await response.json();

        if (action === "insert") {
            setNotification(true);
            liveStreamList.currentLS && MixpanelTracking.lsBookmark(
                liveStreamList.currentLS.subjectId,
                liveStreamList.currentLS.subjectDescriptionTh,
                liveStreamList.currentLS.id,
                liveStreamList.currentLS.subject,
                liveStreamList.currentLS.grade,
                accessData.account.grade
            );
        } else if (action === "delete") {
            setNotification(false);
        }

        return responseData;
    } catch (err) {
        console.log(err);
    }
};



export const checkNotification = async (
    accessData: NaTypes.Authenticate,
    liveStreamList: NaTypes.LiveStreamTableList,
    setNotification: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (!accessData.accessToken) return;

    const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/notification?ls_id=${liveStreamList.currentLS?.id}&user_id=${accessData.accountId}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': generateToken(accessData.accountId!, accessData.accessToken!),
            },
        });

        const { data } = await response.json();

        setNotification(data.length > 0);
    } catch (err) {
        console.error(err);
    }
};



export const checkAutoPlay = async (
    liveStreamList: NaTypes.LiveStreamTableList,
    setVideoList: React.Dispatch<React.SetStateAction<Array<any>>>,
) => {
    const { currentLS } = liveStreamList;

    if (currentLS?.type === "Auto" && currentLS.urlVideo && currentLS.urlVideo !== "" && currentLS.videoId) {
        try {
            const res = await fetch(`https://api.vimeo.com/videos/${currentLS.videoId}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
                },
            });

            const videoData = await res.json();
            setVideoList(videoData.files);
        } catch (error) {
            console.error(error);
        }
    }
};
