import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../../firebase/Firebase';
import { seekTime, getDate, getTime, handleNotification } from './LiveStreamUtils';
import VideoPlayer from '../video-player/VideoPlayer';
import longArrowRight from '../../images/actions/long-arrow-right.svg';
import bookmark from '../../images/notification/BookMarkIcon.svg';
import bookmarkOn from '../../images/notification/bookMarkIconOn.svg';
import ViewerIcon from '../../images/comment/viewer_grey.png';



export const LiveStreamVideo = (props: {
    accessData: NaTypes.Authenticate,
    invitedUserIDs: number[],
    liveStreamList: NaTypes.LiveStreamTableList,
    videoList: Array<any>,
}) => {
    const aid = props.accessData.accountId ? props.accessData.accountId : '';
    const currentLS = props.liveStreamList.currentLS;
    const isAuto = currentLS?.type === 'Auto';

    const { database } = useContext(FirebaseContext);
    const [viewerCount, setViewerCount] = useState<number>(0);



    const fetchMessageListAll = async (ref: firebase.database.Reference) => {
        try {
            const snapshot = await ref.orderByKey().once('value');
            const messageList = Object.keys(snapshot.val() || {}).map((key) => {
                const vals = snapshot.val()[key];
                return {
                    userId: vals.Id.toString(),
                };
            });
            const viewerSet = new Set(messageList.map((m) => m.userId));
            setViewerCount(viewerSet.size);
        } catch (error) {
            console.error("Error fetching message list:", error);
        }
    };



    useEffect(() => {
        if (!database || !currentLS || !currentLS.urlVideo) return;
        const ref = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${currentLS.id}/`);
        fetchMessageListAll(ref);
    }, [database, currentLS]);




    return (
        !currentLS || !currentLS.urlVideo || (currentLS.invitational === 1 && !props.invitedUserIDs.map(String).includes(aid)) ? (
            <Link to={`/live/${currentLS?.id}`} className="live-stream-image-container">
                <img
                    className='live-stream-image col-12 p-0 h-100'
                    src={currentLS?.scheduleImagePath}
                    alt={currentLS?.scheduleImagePath}
                />
            </Link>
        ) : (
            <Link to={`/live/${currentLS?.id}`} className="live-stream-video-container col-12 col-sm-6 p-0" onClick={(e) => e.stopPropagation()}>
                <div className="live-stream-broadcasting-container">
                    <div className="live-stream-broadcasting-text">
                        <svg height="10" width="10">
                            <ellipse cx="5" cy="5" rx="5" ry="5" style={{ fill: '#ffffff' }} />
                        </svg>
                        <span style={{ marginLeft: '5px' }}>กำลังไลฟ์สด</span>
                    </div>
                    <div className="live-stream-broadcasting-tag" id="live-stream-broadcasting-viewer">
                        <img src={ViewerIcon} alt="Viewer Icon" />
                        <span>{viewerCount}</span>
                    </div>
                </div>

                <VideoPlayer
                    fileList={isAuto ? props.videoList : [{ link: currentLS.urlVideo, quality: 'hls' }]}
                    canPlayHandler={() => { }}
                    currentTimeHandler={() => { }}
                    playHandler={() => { }}
                    pauseHandler={() => { }}
                    isLiveStream={true}
                    miniSize={true}
                    muted={true}
                    imagePreview={currentLS.scheduleImagePath}
                    isAutoPlay={isAuto}
                    seekTime={isAuto ? seekTime(props.liveStreamList) : undefined}
                    mainPage={true}
                />
            </Link>
        )
    );
};



export const LiveStreamDetails = (props: {
    accessData: NaTypes.Authenticate,
    dispatch: Dispatch<any>,
    gradeState: NaTypes.GradeState,
    invitedUserIDs: number[],
    liveStreamList: NaTypes.LiveStreamTableList,
    notificationCheck: boolean,
    setNotification: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const aid = props.accessData.accountId ? props.accessData.accountId : '';
    const currentLS = props.liveStreamList.currentLS;

    const handleBookmarkClick = (action: "insert" | "delete") => {
        handleNotification(currentLS?.id, action, props.accessData, props.dispatch, props.setNotification, props.liveStreamList);
    };

    const liveStreamDetailTitle = (
        <h2 className='live-stream-detail-title rainbow_text_animated'>
            {currentLS?.subject}
        </h2>
    );

    return (
        <div className='col-12 col-sm-6 p-0'>
            <div className="row live-stream-detail-container">
                <div className='col-12 live-stream-details'>
                    {currentLS && !currentLS?.urlVideo ? (
                        <div className='live-stream-detail-date'>
                            <span>{getDate(currentLS.startTime)}</span>
                            <span>( {getTime(currentLS.startTime, currentLS.endTime)} )</span>
                        </div>
                    ) : (
                        <span className='live-stream-detail-date-live' style={{ display: window.innerWidth > 575 ? 'inline' : 'none' }}>กำลังไลฟ์สด</span>
                    )}
                    <div className="live-stream-detail-title-container">
                        {window.innerWidth <= 575 ? (
                            <Link to={`/live/${currentLS?.id}`}>
                                {liveStreamDetailTitle}
                            </Link>
                        ) : (
                            liveStreamDetailTitle
                        )}
                        {!currentLS?.urlVideo ? (
                            props.notificationCheck ? (
                                <img onClick={() => handleBookmarkClick("delete")} className="live-stream-bookmark" src={bookmarkOn} />
                            ) : (
                                <img onClick={() => handleBookmarkClick("insert")} className="live-stream-bookmark" src={bookmark} />
                            )
                        ) : null}
                    </div>
                    <h5 className='live-stream-detail-description'>
                        {currentLS?.subjectDescriptionTh} {props.gradeState.grades.find((s) => s.grade === currentLS?.grade)?.descriptionTh} ({currentLS?.instructorName})
                    </h5>
                </div>

                <div className='col-12 live-stream-enter-button-container'>
                    {currentLS?.invitational == 1 && !props.invitedUserIDs.map(String).includes(aid) ? (
                        <button className='live-stream-enter-button'>
                            <a href='https://www.facebook.com/NockAcademyElementary/posts/pfbid03h6uvo1mp8g8im6pxHLk96m5dM6rr25efH8HYqJU4RRJGgMtPDpWwDN3aAhKFnKyl' target="_blank">
                                <span>อ่านรายละเอียดเพิ่มเติม</span>
                                <img src={longArrowRight} alt="Right Arrow" />
                            </a>
                        </button>
                    ) : (
                        <Link to={`/live/${currentLS?.id}`}>
                            <button className='live-stream-enter-button'>
                                <span>เข้าชมไลฟ์สด</span>
                                <img src={longArrowRight} alt="Right Arrow" />
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};



export const LiveStreamLoading = () => {
    return (
        <div className='live-stream-container'>
            <p className='gradient-loading' style={{ width: '140px', height: '22px' }}></p>
            <div className='live-stream-current-container'>
                <div className='live-stream-image gradient-loading' style={{ height: '200px' }}></div>
                <div className='live-stream-detail-container'>
                    <div>
                        <p className='gradient-loading' style={{ width: `${Math.floor(Math.random() * 150) + 100}px`, height: '40px' }}></p>
                        <p className='gradient-loading' style={{ width: `${Math.floor(Math.random() * 100) + 100}px`, height: '20px' }}></p>
                        <p className='gradient-loading' style={{ width: `${Math.floor(Math.random() * 100) + 100}px`, height: '20px' }}></p>
                    </div>
                </div>
            </div>
        </div>
    );
};
