import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowingBoard } from '../../stores/notice-board/NoticeBoardState';

import closeImage from '../../images/actions/close_white_no_bg.svg';
import announcementImage from '../../images/promote/annousment.svg';
import './NoticeBoard.css';

interface NoticeBoardData { notice_boards: { description: string; id: number; status: string; title: string; }[]; }



const NoticeBoard = () => {

    const dispatch = useDispatch();
    const showingNoticeBoard = useSelector((state: NaTypes.Store) => state.noticeBoard.showing);
    const [noticeBoardData, setNoticeBoardData] = useState<NoticeBoardData>();

    const notice = noticeBoardData?.notice_boards?.[0];



    useEffect(() => {
        const fetchNoticeBoard = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v5/method/noticeBoard.get`);
                if (!res.ok) throw new Error("Failed to fetch notice board");
                setNoticeBoardData(await res.json());
            } catch (error) {
                console.error("Error fetching notice board:", error);
            }
        };
        fetchNoticeBoard();
    }, []);




    if (!showingNoticeBoard || (!notice?.title && !notice?.description)) return null;

    return (
        <div className="notice-board-container">
            <img className="notice-board-megaphone" src={announcementImage} alt="Notice Board Megaphone" />
            <div className="notice-board-text-container">
                <span className="notice-board-title">{notice.title}</span>
                <span className="notice-board-text">{notice.description}</span>
            </div>
            <img className="notice-board-close-button" src={closeImage} alt="Close Notice Board" onClick={() => dispatch(setShowingBoard(false))} />
        </div>
    );
};

export default NoticeBoard;
