const initialState: NaTypes.CorrectAndIncorrectOverlay = { show: false };

const SHOW_CORRECT_OVERLAY = 'SHOW_CORRECT_OVERLAY';
const showCorrect = () => { return { type: SHOW_CORRECT_OVERLAY }; };

const SHOW_INCORRECT_OVERLAY = 'SHOW_INCORRECT_OVERLAY';
const showIncorrect = () => { return { type: SHOW_INCORRECT_OVERLAY }; };

const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
const close = () => { return { type: CLOSE_OVERLAY }; };

export { showCorrect, showIncorrect, close };

export function CorrectAndIncorrectState(state: NaTypes.CorrectAndIncorrectOverlay = initialState, action: any): NaTypes.CorrectAndIncorrectOverlay {
    switch (action.type) {
        case SHOW_CORRECT_OVERLAY:
            return Object.assign({}, state, { show: true, correct: true });
        case SHOW_INCORRECT_OVERLAY:
            return Object.assign({}, state, { show: true, correct: false });
        case CLOSE_OVERLAY:
            return Object.assign({}, state, { show: false });
        default:
            return state;
    }
};
