import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import userProfile from '../../images/user/user.svg';

interface ChatMessageProps {
    message: MessageItem;
    open: boolean;
    anchorEl: HTMLDivElement | HTMLButtonElement | null;
    handleClick: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
    handleClose: () => void;
    handleSelect: (value: string, key: string, userId: string) => void;
    messageText: string;
    blurForUser: boolean;
}


const ChatMessage: React.FC<ChatMessageProps> = ({ message, blurForUser, open, anchorEl, handleClick, handleClose, handleSelect, messageText }) => {
    const options = [
        { value: 'delete', text: 'ลบ' },
    ];

    const adminOptions = [
        { value: 'delete', text: 'ลบ' },
        { value: 'mute', text: 'เงียบ' },
        { value: 'ban', text: 'แบน' },
    ];

    return (
        <div
            className={`chat-message-item ${blurForUser ? 'chat-message-item-blur' : ''}`}
            onClick={handleClick}
        >
            <img
                className={`chat-message-item-image-profile`}
                src={message.userImageUrl === '' ? userProfile : message.userImageUrl}
                alt=""
            />

            <div className="chat-message-item-message-container">
                <div>
                    <div className="chat-message-item-text-container">
                        <div
                            className={`chat-message-item-text ${message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                ? 'admin'
                                : message.self
                                    ? 'self'
                                    : 'other'
                                } no-select `}
                        >
                            <div
                                className={`chat-message-item-username-${message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                    ? 'admin'
                                    : message.self
                                        ? 'self'
                                        : 'other'
                                    }`}
                            >
                                {message.userName}
                            </div>
                            {messageText}
                        </div>
                    </div>
                </div>
            </div>

            {(message.self || message.admin) && (
                <div className="chat-message-item-three-dots-menu-container">
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{ style: { maxHeight: 48 * 4.5, width: '10ch' } }}
                    >
                        {message.self
                            ? options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                >
                                    {option.text}
                                </MenuItem>
                            ))
                            : message.admin
                                ? adminOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                    >
                                        {option.text}
                                    </MenuItem>
                                ))
                                : null}
                    </Menu>
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
